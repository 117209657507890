import useUI from "../../../hooks/ui.hook";
import Basic_DateWall from "./Basic/Functions/dateWall";
import Heurus_DateWall from "./Specific/heurus/Functions/dateWall";
import Ovelia_DateWall from "./Specific/ovelia/Functions/dateWall";

export const DateWall = (ctx, repas) => {
  const [ui] = useUI();
  const template = ui.establishment.template;
  if (ui?.groupement?.specific === "heurus") return Heurus_DateWall(ctx, template, repas);
  if (ui?.groupement?.specific === "ovelia") return Ovelia_DateWall(ctx, template, repas);
  return Basic_DateWall(ctx);
}