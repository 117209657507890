import React, {useCallback} from "react";
import moment from "moment";

import { CheckmarkCircleOutline, CheckmarkSharp } from "react-ionicons";
import { filterDishByMenu, sortDishes, sortMenus, isMenuEmpty } from "../../../helpers/operations";

import PropTypes from "prop-types";
import styles from "assets/scss/pages/Menu/menuCard.module.scss";
import useFeature from "hooks/useFeature";
import useUI from "hooks/ui.hook";

import { useMenu } from "../../../router_context";

const RepasComponent = ({ repas }) => {
  const [ctx, dispatch] = useMenu();
  const [ui] = useUI();

  const isSenior = ui.user.role === "senior";
  const isReservation = useFeature("reservationMenu");
  const template = ui.establishment.template;

  const _uid = ui.user.uid;

  const hasBookedDish = (category, dishId) => {
    let retour = false;

    if (
      ctx?.data[ctx?.selectedDate]?.reservation &&
      ctx?.data[ctx?.selectedDate]?.reservation[_uid] &&
      ctx?.data[ctx?.selectedDate]?.reservation[_uid][repas] &&
      ctx?.data[ctx?.selectedDate]?.reservation[_uid][repas].length > 0
    ) {
      if (
        ctx?.data[ctx.selectedDate]?.reservation[_uid][repas][0][category] ===
        dishId
      )
        retour = true;
    }

    return retour;
  };

  const menuList = sortMenus(template, repas, Object.keys(template[repas].menus));
  const menu = repas === "Déjeuner" ? menuList[1] : menuList[0];
  const insert = (arr, index, item) => {
    return [
      ...arr.slice(0, index),     // première moitié
      item,                       // éléments à insérer
      ...arr.slice(index)         // Deuxième partie
    ];
  };

  const getDayDishes = useCallback(() => {
    const _menu = menuList[0];
    const plats = sortDishes(filterDishByMenu(Object.entries(ctx.data[ctx.selectedDate][repas]["plat"] ?? {}),_menu));
    const garniture = sortDishes(filterDishByMenu(Object.entries(ctx.data[ctx.selectedDate][repas]["garniture"] ?? {}),_menu));

    let retour = "";
    if(plats.length > 0) retour+=plats[0][1].name + ", ";
    if(garniture.length > 0) retour+=garniture[0][1].name;

    return retour;
  },[repas, ctx.selectedDate, ctx.data]);

  const categs = repas === "Déjeuner" && moment(ctx.selectedDate).weekday() < 5 ? insert(template[repas].menus[menu].categories, 3, "plat du jour") : template[repas].menus[menu].categories;

  return (
    <div className={styles.container}>
      <div className={styles.title}>{repas}</div>
      <div className={styles.separator2}></div>
      <div className={styles.categContainer}>
        {categs.map((category, indx) =>
          ctx?.data[ctx.selectedDate] &&
            ctx?.data[ctx.selectedDate][repas] &&
            ((category === "plat du jour" && !isMenuEmpty(ctx?.data[ctx.selectedDate][repas], menuList[0])) || (ctx?.data[ctx.selectedDate][repas][category] &&
              filterDishByMenu(
                Object.entries(ctx.data[ctx.selectedDate][repas][category]),
                menu
              ).length > 0)) ? (
            <div key={indx} className={styles.categoryDiv}>
              <span className={styles.categoryTitle}>{repas === "Déjeuner" && moment(ctx.selectedDate).weekday() === 6 && category === "plat" ? "cocotte" : category}</span>
              <div className={styles.dishContainer}>
                {category === "plat du jour" ? 
                    <div>{getDayDishes()}</div>
                 : sortDishes(
                  filterDishByMenu(
                    Object.entries(ctx.data[ctx.selectedDate][repas][category]),
                    menu
                  )
                ).map(([id, dish], index) => (
                  <>
                    {isReservation ? (
                      hasBookedDish(category, id) ? (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#b8d7b850",
                              fontWeight: "bold",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              paddingLeft: 5,
                              paddingRight: 5,
                              marginLeft: -25,
                            }}
                          >
                            <CheckmarkSharp
                              color={"green"}
                              height="25px"
                              width="25px"
                            />
                            {dish.name}
                          </div>
                        </div>
                      ) : (
                        <div key={index}>{dish.name}</div>
                      )
                    ) : (
                      <div key={index}>{dish.name}</div>
                    )}
                  </>
                ))}
              </div>
              <div className={styles.separator}></div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

RepasComponent.propTypes = {
  repas: PropTypes.string.isRequired,
  menu: PropTypes.string.isRequired,
};

export default RepasComponent;
