import dayjs from "dayjs";

/*
Lundi -> vendredi:
doit être réservé la veille avant 10h sinon bloque les résas le midi le soir

Samedi - dimanche
doit être réservé vendredi avant 10h sinon bloque les résas sur tout le week end

renvoi false si la date est bloquée, sinon true
*/
const dateWall = (ctx, template, repas) => {
  if (repas === "Déjeuner") return true;

  if (dayjs(ctx.selectedDate).day() === 0) {// dimanche
    return !dayjs().isAfter(dayjs(ctx.selectedDate).subtract(2, 'd').set('hour', 9).set('minute', 30));
  } else if (dayjs(ctx.selectedDate).day() === 6) {// samedi
    return !dayjs().isAfter(dayjs(ctx.selectedDate).subtract(1, 'd').set('hour', 9).set('minute', 30));
  } else {// lundi -> vendredi
    return !dayjs().isAfter(dayjs(ctx.selectedDate).set('hour', 9).set('minute', 30));
  }
}

export default dateWall